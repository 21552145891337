export const ageMethod = "age nearest";
export const gateway = "https://mitch-api.link.wawanesalife.com";
export const assets = "https://stable-assets.link.wawanesalife.com/brokers/mitch";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const appToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtaXRjaCIsIm5hbWUiOiJtaXRjaCIsImlhdCI6MTczMzM3MTgwNSwiZXhwIjoxODkxMTU5ODA1fQ.Nf1V_27hzRZgdPdr2Md-cbB2-xey61No63gN5QMVYRE";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"mitch.link.wawanesalife.com": {
		a: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: [
				"110 Yonge St. Suite 403",
				"Toronto",
				"ON",
				"M5C 1T4",
			],
			environment_id: "stable",
			map1: [
				"1555 Wentworth St. Unit 200, Whitby",
				"Whitby",
				"ON",
				"L1N 9T6",
			],
			brands: [
				"mitch.link.wawanesalife.com",
			],
			map4: "hide",
			map3: "hide",
			"maps": {
				"map2": [
					"110 Yonge St. Suite 403",
					"Toronto",
					"ON",
					"M5C 1T4",
				],
				"map1": [
					"1555 Wentworth St. Unit 200, Whitby",
					"Whitby",
					"ON",
					"L1N 9T6",
				],
			},
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
			],
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
		b: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: [
				"110 Yonge St. Suite 403",
				"Toronto",
				"ON",
				"M5C 1T4",
			],
			environment_id: "stable",
			map1: [
				"1555 Wentworth St. Unit 200, Whitby",
				"Whitby",
				"ON",
				"L1N 9T6",
			],
			brands: [
				"mitch.link.wawanesalife.com",
			],
			map4: "hide",
			map3: "hide",
			"maps": {
				"map2": [
					"110 Yonge St. Suite 403",
					"Toronto",
					"ON",
					"M5C 1T4",
				],
				"map1": [
					"1555 Wentworth St. Unit 200, Whitby",
					"Whitby",
					"ON",
					"L1N 9T6",
				],
			},
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photos: [
				"carousel1.jpg",
				"carousel2.jpg",
				"carousel3.jpg",
				"carousel4.jpg",
				"carousel5.jpg",
			],
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
	},
};

//updated on Wed Dec 04 2024 22:10:05 GMT-0600 (Central Standard Time)
